<template>
  <div class="privacy-and-cookie" v-if="!$store.state.loading">
    <h1 v-html="$t('privacy-title')"></h1>

    <div class="section">
      <h2 v-html="$t('privacy-owner-title')"></h2>
      <p v-html="$t('privacy-owner-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-collect-title')"></h2>
      <p v-html="$t('privacy-collect-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-do-title')"></h2>
      <p v-html="$t('privacy-do-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-security-title')"></h2>
      <p v-html="$t('privacy-security-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-use-title')"></h2>
      <p v-html="$t('privacy-use-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-links-title')"></h2>
      <p v-html="$t('privacy-links-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-controlling-title')"></h2>
      <p v-html="$t('privacy-controlling-paragraph')"></p>
    </div>
    <div class="section">
      <h2 id="list" v-html="$t('privacy-list-cookies-title')"></h2>
      <h3 v-html="$t('privacy-user-info-title')"></h3>
      <h4 v-html="$t('privacy-user-info-form-title')"></h4>
      <p v-html="$t('privacy-user-info-form-paragraph')"></p>
      <h3 v-html="$t('privacy-performance-title')"></h3>
      <p v-html="$t('privacy-performance-paragraph')"></p>
      <h4 v-html="$t('privacy-performance-analytics-title')"></h4>
      <p v-html="$t('privacy-performance-analytics-paragraph')"></p>
      <h3 v-html="$t('privacy-external-title')"></h3>
      <h4 v-html="$t('privacy-external-maps-title')"></h4>
      <p v-html="$t('privacy-external-maps-paragraph')"></p>
    </div>
    <div class="section">
      <h2 v-html="$t('privacy-disable-title')"></h2>
      <p v-html="$t('privacy-disable-paragraph')"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.commit('setFooterVisible')

        //simulate resize for scrolling transitions
        let event
        if (typeof Event === 'function') {
          event = new Event('resize')
        } else {
          //IE
          event = document.createEvent('Event')
          event.initEvent('resize', true, true)
        }
        window.dispatchEvent(event)
      }, 1100)
    })
  }
}
</script>

<style lang="scss"></style>
